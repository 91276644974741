import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLocationDot } from "react-icons/fa6";
import Palani_1 from "./Palani Images/arulmigu-dhandayuthapani-swamy-temple-palani-1657867829_3fda42dd1b3ef365f142.webp";
import Palani_2 from "./Palani Images/The Six Abodes of Lord Muruga (Aru Padai Veedugal).jpeg";
import { TextField, Select, MenuItem } from '@mui/material';
import "./Palani.css";

const KanyaKumari = () => {
  return (
    <div>
      <div className="palani_banner">
        <h1 className="palani_banner_h1">Palani Sightseeing Places</h1>
        <center>
          <p className="palani_banner_p">Home |Palani</p>
        </center>
      </div>

      <Container className="mb-5 p-3">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12} className="mt-5">
            <iframe
             className="iframe"
              src="https://www.youtube.com/embed/E-unkbpxaHQ?si=V9I7aF66BQYanqkW"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

            <h2 className="mb-3">Best Places To Visit In Madurai</h2>
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                marginLeft: "-30px",
              }}
            >
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Palani Hills
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Lord Murugan Temple
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Idumban Temple
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Shri Periya Nayaki Amman Temple
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Arulmigu Dhandayuthapani Swamy Temple
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Golden chariot
              </li>
            </ul>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xm={12} className="mt-5 ">
  <TextField
    type="text"
    label="Enter your Name*"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="Enter your Number"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="email"
    label="Enter Your Email Address"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="text"
    label="Pick Up Your Location"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <Select
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
    displayEmpty
    defaultValue=""
  >
    <MenuItem value="" disabled>Select Your Vehicle</MenuItem>
    <MenuItem value="Mini Car">Mini Car</MenuItem>
    <MenuItem value="Sedan Car">Sedan Car</MenuItem>
    <MenuItem value="SUVs Car">SUVs Car</MenuItem>
    <MenuItem value="Tempo Travelers">Tempo Travelers</MenuItem>
  </Select>
  <TextField
  type="date"
  label="Date / Time"
  variant="outlined"
  className="input"
  fullWidth
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
  <TextField
    type="number"
    label="No of Adults"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="No of Children"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    id="w3review"
    name="w3review"
    label="Enter Your Message"
    variant="outlined"
    multiline
    rows={4}
    className="input"
    fullWidth
    margin="normal"
  /> 
  <div>
  <br/>
  </div>
  
  <Button startIcon={<WhatsAppIcon />} className="whatsUp_btn">
    What's Up
  </Button>
</Col>
        </Row>
      </Container>

      {/* Vivek */}

      <Container>
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Palani_1}
                alt="Vivekananda Rock Memorial is located where three oceans meet the Bay of Bengal, Indian Ocean and Arabian Sea; it is located 400 meters from Kanyakumari"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">
              Arulmigu Dhandayuthapani Swamy Temple
            </h2>
            <p className="mt-3 text-align-justify p">
              {" "}
              Arulmigu Dhandayuthapani Swamy Temple is third of the Six Abodes
              of Murugan. It is located in the city of Palani earlier it was
              known as Thiruaavinankudi, Dindigul district, 100 kilometres
              southeast of Coimbatore and northwest of Madurai in the foothills
              of the Palani Hills, Tamil Nadu, India. Palani temple is
              considered synonymous with Panchamritam, a sweet mixture made of
              five ingredients. As per Hindu legendary beliefs, Sage Narada
              visited the celestial court of Shiva at Mount Kailash to present
              to him a fruit, the gnana-palam. He decided to award it to
              whichever of his two sons who first encircle the world thrice.
              Accepting the challenge, Murugan started his journey around the
              globe on his mount peacock. However, Ganesha, who surmised that
              the world was no more than his parents Shiva and Shakti combined,
              circumambulated them and won the fruit. Murugan was furious and
              felt the need to get matured from boyhood and hence chose to
              remain as a hermit in Palani. The idol of the Muruga in Palani was
              created and consecrated by sage Bogar, one of Hinduism’s eighteen
              great siddhaars, out of an amalgam of nine poisonous herbs or
              Navapashanam.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Palani_2}
                alt="Vivekananda Rock Memorial is located where three oceans meet the Bay of Bengal, Indian Ocean and Arabian Sea; it is located 400 meters from Kanyakumari"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">Thiruparankundram</h2>
            <p className="mt-3 text-align-justify p">
              Palani is home to one of the most sacred shrines of the Lord
              Muruga, as worshiped in the Hindu sect of Kaumaram. The
              Thandayudhapani Temple dedicated to Lord Murugan “Palani Andavar”,
              and regarded one of his Arupadai Veedu (Six Battle Camps), is
              situated here. The temple is situated atop a hill known as
              Sivagiri. The Garbagriham is surmounted by a gold gopuram and the
              walls of the Garbagriham have numerous stone inscriptions
              describing offerings made by devotees to the temple. Steps are
              hewn into the rock, besides a wide path meant for the ascent of
              elephants, up the hill. In addition, a winch pulled railway with
              three tracks and a rope way are operational. A temple is dedicated
              to Murugan near the foot of the hill by the name of Thiru Avinan
              kudi which actually forms a part of the six abodes of Muruga
              (Arupadaiveedu). It is also called as Kulandai Velayudhaswami
              Thirukkovil..
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KanyaKumari;
