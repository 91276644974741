import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoIosArrowForward } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdContactMail } from "react-icons/md";
import { SiInstagram } from "react-icons/si";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import Logo from './Images/logo1.png'
import "./Footer.css";
import { Link } from "react-router-dom";

const footer = () => {
  return (
    <div className="footer_container_div p-5">
      <Container className="mt-5 justify-content-center">
        <Row className="justify-content-center">
        <Col xxl={2} xl={2} lg={2} md={12} sm={12} className="justify-content-center">
          <div className="logo mb-5">
            <img src={Logo} alt="Abu Tours and Travels"  className="footer_logo" />
          </div>
          </Col>

          <Col xxl={5} xl={5} lg={5} md={12} sm={12} className="justify-content-center">
            <h3>About</h3>
            <p className="footer_p mb-5">
              About We are a popular and leading Cab, Tempo Traveller, Mini Bus,
              Bus and Luxury Vehicle rental travel company based out of Madurai.
            </p>

            <p>
            <FaLocationDot className="footer_location_icon  p-2 " /> 151,Dobi Colony, Alaghar kovil main road, K.Pudur. Madurai.
            </p>
          </Col>

          <Col xxl={2} xl={2} lg={2} md={12} sm={12}>
            <h3>Quick Link</h3>
            <br />
           <Link style={{textDecoration:'none',color:'white'}} to='/'><p><IoIosArrowForward  className="footer_icon_color"/> Home</p></Link> 
           <a href="#packages" style={{textDecoration:'none',color:'white'}}> <p><IoIosArrowForward className="footer_icon_color" /> Packages</p></a>
         <Link style={{textDecoration:'none',color:'white'}} to='car_rental'> <p><IoIosArrowForward className="footer_icon_color" /> Car Rentals</p></Link>  
          <a href="#book_now" style={{textDecoration:'none',color:'white'}}><p><IoIosArrowForward  className="footer_icon_color"/> Contact</p></a>  
            
          </Col>
          <Col xxl={2} xl={2} lg={2} md={12} sm={12}>
            <h3>UseFull Link</h3>
            <br />
           <Link to='madurai' style={{textDecoration:'none',color:'white'}}> <p><IoIosArrowForward  className="footer_icon_color"/> Madurai</p></Link>
           <Link to='kodaikanal' style={{textDecoration:'none',color:'white'}}> <p><IoIosArrowForward  className="footer_icon_color"/> Kodaikanal</p></Link>
           <Link to='rameshwaram' style={{textDecoration:'none',color:'white'}}> <p><IoIosArrowForward  className="footer_icon_color"/> Rameshwaram</p></Link>
           <Link to='palani' style={{textDecoration:'none',color:'white'}}> <p><IoIosArrowForward  className="footer_icon_color"/> Palani</p></Link>
           <Link to='kerala' style={{textDecoration:'none',color:'white'}}> <p><IoIosArrowForward  className="footer_icon_color"/> Kerala</p></Link>
           <Link to='kanyakumari' style={{textDecoration:'none',color:'white'}}> <p><IoIosArrowForward  className="footer_icon_color"/> Kanyakumari</p></Link>
           
           
          
           
          </Col>
        
        </Row>
        <hr />
        <Row>
        <Col xxl={4} xl={4} lg={4} md={4} sm={12}  >
          <h3 className="mb-3">Book Your Holiday</h3>
        <p><FaPhoneAlt className="footer_location_icon  p-2 "/> +91 93603 71716</p>
        <p><FaPhoneAlt className="footer_location_icon  p-2 " /> +91 82700 07225</p>
        </Col>


        <Col xxl={4} xl={4} lg={4} md={4} sm={12} >
            <h3 className="mb-3"> Email</h3>
            <p><MdContactMail  className="footer_location_icon  p-2 " /> abubakkarsidhik199@gmail.com</p>
            </Col>

        <Col xxl={4} xl={4} lg={4} md={4} sm={12} >
            <h3 className="mb-3">Follow Us</h3>
            <div className="footer_icons_container">
            <p><SiInstagram className="social_media_icon p-1" /></p>
            <p><FaFacebookSquare className="social_media_icon p-1" /></p>
            <p><FaLinkedin className="social_media_icon p-1" /></p>
            <p><FaTwitter className="social_media_icon p-1" /></p>
            </div>
        
            </Col>
            </Row>
            <hr />
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
<center>
    <p>The copyright symbol: &copy; 2022 Abu Tours and Travels. All rights reserved. Developed by Findway Digital</p>
</center>
                    
                </Col>
            </Row>
      </Container>
    </div>
  );
};

export default footer;
