import React from 'react'

const NoPageFound = () => {
  return (
    <div>
      <center>
        <h1>404 Page Not Found </h1>
      </center>
    </div>
  )
}

export default NoPageFound
