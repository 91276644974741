import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const BookNow = () => {
  return (
    <div className='mt-5 mb-5'>
    {/* .Get In Touch */}

    <Container className="mt-5" id="book_now">
        <Row>
          <center>
            <p>Get In Touch</p>
            <h1>Book Your Trip</h1>
          </center>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
            map
          </Col>

          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12} className="mt-5">
            <input
              type="text"
              placeholder="Enter your Name* "
              className="input"
            />
            <br />
            <br />
            <input
              type="number"
              placeholder="Enter your Number"
              className="input"
            />
            <br />
            <br />
            <input
              type="email"
              placeholder="Enter Your Email Address"
              className="input"
            />
            <br />
            <br />
            <input
              type="text"
              placeholder="Pick Up Your Location"
              className="input"
            />
            <br />
            <br />
            <select name="" id="" className="input">
              <option value=""> Select Your Vechicle </option>
              <option value="">Mini Car</option>
              <option value="">Sedan Car</option>
              <option value="">SUVs Car</option>
              <option value="">Tempo Travelers</option>
            </select>
            <br />
            <br />
            <input type="date" placeholder="Date / Time" className="input" />
            <br />
            <br />
            <input type="number" placeholder="No of Adults" className="input" />
            <br />
            <br />
            <input
              type="number"
              placeholder="No of Childrens"
              className="input"
            />
            <br />
            <br />
            <textarea
              id="w3review"
              name="w3review"
              placeholder="Enter Your Message"
              className="input"
            ></textarea>
            <br />
            <Button startIcon={<WhatsAppIcon />} className="whatsUp_btn">
              What's Up
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BookNow
