import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Car_1 from "./Car-Rental_images/hatchback-1.jpg";
import Car_2 from "./Car-Rental_images/innova.jpg";
import Car_3 from "./Car-Rental_images/xylo-car.png";
import Accordion from "react-bootstrap/Accordion";
import "./Car_rentals.css";

const Car_rentals = () => {
  return (
    <div>
      <div className="car_rental_banner">
        <h1 className="car_rental">Car For Rent In Madurai</h1>
      </div>
      <br />
      <br />
      <Container>
        <Row>
          <Col>
            <center>
              <h1>CAR FOR RENT IN MADURAI</h1>

              <p >We provide the service rent a car in madurai with driver</p>
              <p className="all_p">
                Our car rental company has been operating in India, offering a
                wide range of cars for rent in Maduri to suit your needs. every
                need and budget. Our fleet includes everything from compact
                hatchbacks and sedans to spacious SUVs and luxury cars, all
                well-maintained and equipped with the latest amenities.{" "}
              </p>
              <p className="all_p">
                We pride ourselves on providing exceptional customer service.
                and flexible rental options, including daily, weekly, and
                monthly rentals. Our team of dedicated professionals is always
                ready to assist you with your car rental needs , whether you’re
                going on a road trip or attending a special event. event, or
                need a reliable mode of transportation for business. or other
                personal use.
              </p>
              <p className="all_p">
                Our goal is to make your car rental experience seamless. and
                enjoyable as possible, so you can focus on your travels. and
                make the most of your time in India. Choose us for all your car
                rental needs and experience the best in comfort, convenience and
                value . Our one of the best service is innova car rental in
                Madurai.
              </p>
            </center>
          </Col>
        </Row>

        <Row className="mb-5">
          <center>
            <h1>Our Popular Vehicles</h1>
          </center>
          <Col xxl={4} xl={4} lg={6} md={12} sm={12} xm={12}>
           <div className="car_shadow">

            <img src={Car_1} alt="" className="rental_car_image p-4" />
            <center>
              <h3>ETIOS, DZIRE, XCENT</h3>
              <p>Round Trip Rs 13</p>
              <p>Driver Allowance Rs. 300/-</p>
              <p>Round Trip Minimum/ 250 Km</p>
              <button className="car_btn car_btn_1 p-2">More Details</button>
            </center>
            <br />
            </div>
          </Col>

          <Col xxl={4} xl={4} lg={6} md={12} sm={12} xm={12}>
            <div  className="car_shadow" >
            <img src={Car_3} alt="" className="rental_car_image p-4" />
            <center>
              <h3>XYLO, TAVERA, ENJOY</h3>
              <p>Round Trip Rs 16</p>
              <p>Driver Allowance Rs. 400/-</p>
              <p>Round Trip Minimum/ 300 Km</p>
              <button className="car_btn car_btn_1 p-2">More Details</button>
            </center>
            <br />
            </div>
          </Col>

          <Col xxl={4} xl={4} lg={6} md={12} sm={12} xm={12} className="rental_car_col">
            <div className="car_shadow">
            <img src={Car_2} alt="" className="rental_car_image p-4" />
            <center>
              <h3>INNOVA</h3>
              <p>Round Trip Rs 17</p>
              <p>Driver Allowance Rs. 500/-</p>
              <p>Round Trip Minimum/ 300 Km</p>
              <button className="car_btn car_btn_1 p-2">More Details</button>
            </center>
            <br />
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <br />

        <Row className="mt-5 mb-5">
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xm={12}>
            <center>
              <h1>FAQ</h1>
            </center>
            <br />
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Do I need to pick up the car from abu rental office?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                    All the cars are delivered and collected free of cost to the
                    address specified at the time of making a booking. Note;
                    cars will not be delivered to any public places like malls,
                    metro station, etc.
                  </p>{" "}
                  <br />
                  <p>Abu holds the right to cancel the booking in such case.</p>
                </Accordion.Body>
              </Accordion.Item>

            
              <br />
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  I need to drop off the car at a different location, can I do
                  that?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                    No. The car will be collected by our driver from the place
                    to where it was delivered.
                  </p>{" "}
                  <br />
                  <p>Abu holds the right to cancel the booking in such case.</p>
                </Accordion.Body>
              </Accordion.Item>

    
              <br />
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What if I return the car late?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                    A Guest needs to inform Abu in advance for rental extension.
                    An extension or grace period of up to 1 hours is permissible
                    if informed with no extra charges. If the extension exceeds
                    1 hours.
                  </p>{" "}
                  <br />
                  <p>Abu holds the right to cancel the booking in such case.</p>
                </Accordion.Body>
              </Accordion.Item>

          
              <br />
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Is car rental safe during the Pandemic?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                    Car rental is one of the safest options during a pandemic
                    since there will be no other person in the car from whom the
                    virus can transmit. You can include the people in the car
                    who you think are safe to travel with. You can be the driver
                    of the car and use it just like your own car. From using it
                    for daily needs to taking the car for a trip, you can use it
                    the way you want. Eligibility criteria for car rental? Ans:
                    The basic criterion for availing of a car rental service is
                    that you need to be 21 years old or above and have a valid
                    license for four-wheelers. You also need to have an ID and
                    address proof, which has to be submitted to the company
                    while taking the car rental service. You will need to submit
                    the original license, and not one printed on an A4 size
                    paper.{" "}
                  </p>{" "}
                  <br />
                </Accordion.Body>
              </Accordion.Item>

              <br />
        
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  CanI get a car on per k.m. rate?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                    Yes, we can provide you all cars on per k.m. basis as well
                    but normally in that case, parking and all taxes are charged
                    extra and there is a condition of minimum chargeable mileage
                    which varies from city to city.{" "}
                  </p>{" "}
                  <br />
                </Accordion.Body>
              </Accordion.Item>

              <br />
            
              <Accordion.Item eventKey="5">
                <Accordion.Header>Can I cancel my car rental?</Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                    Yes, you can go ahead and cancel the car rental service. You
                    can do it through the website, or just by making a call to
                    Abu. The charges of canceling the booking depend upon the
                    time when you inform the company. If you are taking the
                    service of Abu.
                  </p>{" "}
                  <br />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Car_rentals;
