import { Routes, Route } from "react-router-dom";
import RootLayout from './Component/RootLayout'; // Corrected the import statement
import Home from './Component/Home';
import About from "./Component/About";
import Packages from "./Component/Packages";
import Car_rentals from "./Component/Car_rentals";
import Tempo_travels from "./Component/Tempo_travels";
import BookNow from "./Component/BookNow";
import NoPageFound from "./Component/NoPageFound";
import KanyaKumari from "./Component/KanyaKumari";
import Madurai from "./Component/Madurai";
import Kodaikanal from "./Component/Kodaikanal";
import Rameshwaram from "./Component/Rameshwaram";
import Palani from "./Component/Palani";
import Kerala from "./Component/Kerala";

function App() {
  return (
    <div>
     <Routes>
      <Route path="/" element={<RootLayout/>}>
      <Route index element={<Home/>}/>
      <Route path="about" element={<About/>}/>
      <Route path="packages" element={<Packages/>}/>
      <Route path="kanyakumari" element={<KanyaKumari/>}/>
      <Route path="madurai" element={<Madurai/>}/>
      <Route path="rameshwaram" element={<Rameshwaram/>}/>
      <Route path="kodaikanal" element={<Kodaikanal/>}/>
      <Route path="palani" element={<Palani/>}/>
      <Route path="kerala" element={<Kerala/>}/>
    <Route path="car_rental" element={<Car_rentals/>}/>
      <Route path="tempo_traveler" element={<Tempo_travels/>}/>
<Route path="book_now" element={<BookNow/>}/>
<Route path="*" element={<NoPageFound />} />
      <Route/>
      </Route>
     </Routes>
    </div>
  );
}

export default App;
