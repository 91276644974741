import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLocationDot } from "react-icons/fa6";
import Ramesh_1 from "./Rameshwaram Images/Pamban Bridge, Rameswaram.jpeg";
import Ramesh_2 from "./Rameshwaram Images/Dhanushkodi.jpeg";
import { TextField, Select, MenuItem } from '@mui/material';
import "./Rameshwaram.css";

const KanyaKumari = () => {
  return (
    <div>
      <div className="Rameshwaram_banner">
        <h1 className="Rameshwaram_banner_h1">
          Rameshwaram Sightseeing Places
        </h1>
        <center>
          <p className="Rameshwaram_banner_p">Home | Rameshwaram</p>
        </center>
      </div>

      <Container>
        <Row>
          <Col>
            <center className="mt-5 mb-5">
              <h2>Rameshwaram Package Details</h2>
            </center>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td colSpan={18}>
                    <p>Rameshwaram Sight Seeing</p>
                  </td>
                  <td>
                    <p>₹ 6000</p>
                  </td>
                </tr>

                <tr>
                  <td colSpan={18}>
                    <p>Limited Kilometres</p>
                  </td>
                  <td>450km</td>
                </tr>

                <tr>
                  <td colSpan={18}>
                    <p>Intercity Extra kilometer</p>
                  </td>
                  <td>
                    <p>₹ 12/-</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <p>
            {" "}
            <strong>Notes : </strong>
            Mentioned Price Might Vary Depending On The Season, Increse in Fuel
            Prise, Weekend. Ex. : Saturday – Sunday – New Year – Deepavali Etc.
            (Please Confirm Rates Before Proceeding)i.e
          </p>
        </Row>
      </Container>

      <Container className="mb-5 p-3">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12} className="mt-5">
            <iframe
            className="iframe"
              src="https://www.youtube.com/embed/SVKhzJyiIRM?si=7jSqO7lSil4YMUFu"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

            <h2 className="mb-3">Best Places To Visit In Rameshwaram</h2>
            <ul style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Ramanatha Swamy Temple
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Pamban Bridge
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Lakshmana Kund
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Ramar Kund
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Floating Stone
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Abdul Kalam Memoria
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Thanush Kodi
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Sri Lanka India End
              </li>
            </ul>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xm={12} className="mt-5 ">
  <TextField
    type="text"
    label="Enter your Name*"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="Enter your Number"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="email"
    label="Enter Your Email Address"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="text"
    label="Pick Up Your Location"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <Select
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
    displayEmpty
    defaultValue=""
  >
    <MenuItem value="" disabled>Select Your Vehicle</MenuItem>
    <MenuItem value="Mini Car">Mini Car</MenuItem>
    <MenuItem value="Sedan Car">Sedan Car</MenuItem>
    <MenuItem value="SUVs Car">SUVs Car</MenuItem>
    <MenuItem value="Tempo Travelers">Tempo Travelers</MenuItem>
  </Select>
  <TextField
  type="date"
  label="Date / Time"
  variant="outlined"
  className="input"
  fullWidth
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
  <TextField
    type="number"
    label="No of Adults"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="No of Children"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    id="w3review"
    name="w3review"
    label="Enter Your Message"
    variant="outlined"
    multiline
    rows={4}
    className="input"
    fullWidth
    margin="normal"
  /> 
  <div>
  <br/>
  </div>
  
  <Button startIcon={<WhatsAppIcon />} className="whatsUp_btn">
    What's Up
  </Button>
</Col>
        </Row>
      </Container>

      {/* Vivek */}

      <Container>
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Ramesh_1}
                alt="Vivekananda Rock Memorial is located where three oceans meet the Bay of Bengal, Indian Ocean and Arabian Sea; it is located 400 meters from Kanyakumari"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">Pamban Bridge</h2>
            <p className="mt-3 text-align-justify p">
              Pamban Bridge is a railway bridge that connects the town of
              Mandapam in mainland India with Rameswaram on Pamban Island.
              Opened on 24 February 1914, it was India’s first sea bridge, and
              was the longest sea bridge in India until the opening of the
              Bandra-Worli Sea Link in 2010. The rail bridge is, for the most
              part, a conventional bridge resting on concrete piers, but has a
              double-leaf bascule section midway, which can be raised to let
              ships and barges pass through. Until 1988, the Pamban bridge was
              the only surface transport that connected Tamil Nadu’s island of
              Rameswaram to the mainland. In December 2018, the bascule of this
              bridge was damaged, which suspended transportation on the bridge
              for 3 months. Rail movement was again restored on 27 February
              2019. In 1988, a road bridge was also constructed parallel to the
              rail bridge. This road bridge is also known as Annai Indira Gandhi
              Road Bridge. The Annai Indira Gandhi Road Bridge connects the
              National Highway with the Rameswaram island. It stands on the Palk
              Strait and between the shores of Mandapam and Pamban. It was
              inaugurated by then Indian Prime Minister Rajiv Gandhi on 2
              October 1988.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Ramesh_2}
                alt="Vivekananda Rock Memorial is located where three oceans meet the Bay of Bengal, Indian Ocean and Arabian Sea; it is located 400 meters from Kanyakumari"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">Dhanushkodi Beach</h2>
            <p className="mt-3 text-align-justify p">
              Dhanushkodi Beach lies on the tip of the Rameswaram island. In
              this beach, the Bay of Bengal and Indian Ocean merge which is
              known as Arichal Munai in Tamil. Before 1964, Dhanushkodi was a
              busy, crowded city. The Dhanushkodi Beach attracted thousands of
              tourists each day. In 1964, Dhanushkodi was hit by a cyclone and
              was almost destroyed by it. It is considered a ghost town, as the
              place became uninhabitable. Many tourists still visit Dhanushkodi
              Beach. The main attractions of the beach are Ram sethu view point
              and the Adam’s Bridge, which was said to be constructed by the
              army of monkeys for Lord Rama according to Hindu Legend.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KanyaKumari;
