import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "@mui/material/Button";
import { TextField, Select, MenuItem } from '@mui/material';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLocationDot } from "react-icons/fa6";
import Vivek from "./KanyaKumari Images/Vivekananda Rock Memorial is located where three oceans meet the Bay of Bengal, Indian Ocean and Arabian Sea; it is located 400 meters from Kanyakumari_.jpeg";
import Amman from "./KanyaKumari Images/Untitled-design-4.png";
import "./KanyaKumari.css";

const KanyaKumari = () => {
  return (
    <div>
      <div className="kanyakumari_banner">
        <h1 className="kanyakumari_banner_h1">
          Kanyakumari Sightseeing Places{" "}
        </h1>
        <center>
          <p className="kanyakumari_banner_p"> Home | Kanyakumari</p>
        </center>
      </div>

      <Container>
        <Row>
          <Col>
            <center className="mt-5 mb-5">
              <h2>Kanyakumari Package Details</h2>
            </center>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td colSpan={18}>
                    <p>Kanyakumari Sight Seeing</p>
                  </td>
                  <td>
                    <p>₹ 7000</p>
                  </td>
                </tr>

                <tr>
                  <td colSpan={18}>
                    <p>Limited Kilometres</p>
                  </td>
                  <td>500km</td>
                </tr>

                <tr>
                  <td colSpan={18}>
                    <p>Intercity Extra kilometer</p>
                  </td>
                  <td>
                    <p>₹ 12/-</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <p>
            {" "}
            <strong>Notes : </strong>
            Mentioned Price Might Vary Depending On The Season, Increse in Fuel
            Prise, Weekend. Ex. : Saturday – Sunday – New Year – Deepavali Etc.
            (Please Confirm Rates Before Proceeding)i.e
          </p>
        </Row>
      </Container>

        {/* .Get In Touch */}

        <Container className="mt-5" id="book_now" >
        <Row>
          <center>
            <p className="ephesis">Get In Touch</p>
            <h1>Book Your Trip</h1>
          </center>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
  <div className="map_iframe p-1 mt-5 mb-5" >
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d503014.15652671846!2d78.147984!3d9.95075!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c680d918d067%3A0xacf1aa4b03b7f69a!2sK.Pudur%20E1%20Police%20station%2C%20Madurai%20City!5e0!3m2!1sen!2sus!4v1710757917213!5m2!1sen!2sus"
     className="map_iframe mt-3"
      style={{ border: '0' }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</Col>


<Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12} className="mt-5 ">
  <TextField
    type="text"
    label="Enter your Name*"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="Enter your Number"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="email"
    label="Enter Your Email Address"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="text"
    label="Pick Up Your Location"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <Select
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
    displayEmpty
    defaultValue=""
  >
    <MenuItem value="" disabled>Select Your Vehicle</MenuItem>
    <MenuItem value="Mini Car">Mini Car</MenuItem>
    <MenuItem value="Sedan Car">Sedan Car</MenuItem>
    <MenuItem value="SUVs Car">SUVs Car</MenuItem>
    <MenuItem value="Tempo Travelers">Tempo Travelers</MenuItem>
  </Select>
  <TextField
  type="date"
  label="Date / Time"
  variant="outlined"
  className="input"
  fullWidth
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
  <TextField
    type="number"
    label="No of Adults"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="No of Children"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    id="w3review"
    name="w3review"
    label="Enter Your Message"
    variant="outlined"
    multiline
    rows={4}
    className="input"
    fullWidth
    margin="normal"
  /> 
  <div>
  <br/>
  </div>
  
  <Button startIcon={<WhatsAppIcon />} className="whatsUp_btn">
    What's Up
  </Button>
</Col>
        </Row>
      </Container>
      {/* Vivek */}

      <Container>
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Vivek}
                alt="Vivekananda Rock Memorial is located where three oceans meet the Bay of Bengal, Indian Ocean and Arabian Sea; it is located 400 meters from Kanyakumari"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">
              Vivekananda Rock Memorial
            </h2>
            <p className="mt-3 text-align-justify p">
              {" "}
              Vivekananda Rock Memorial is a monument and popular tourist
              attraction in Kanyakumari, India’s southernmost tip. The memorial
              stands on one of the two rocks located about 500 meters off
              mainland of Vavathurai. It was built in 1970 in honour of Swami
              Vivekananda, who is said to have attained enlightenment on the
              rock. According to legends, it was on this rock that Goddess
              Kanyakumari performed tapas in devotion of lord Shiva. A
              meditation hall known as Dhyana Mandapam is also attached to the
              memorial for visitors to meditate. The design of the mandapa
              incorporates different styles of temple architecture from all over
              India. The rocks are surrounded by the Laccadive Sea where the
              three oceans Bay of Bengal, Indian Ocean and the Arabian Sea
              meets. The memorial consists of two main structures, the
              Vivekananda Mandapam and the Shripada Mandapam.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row>
        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12} >
            <div className="vivek_image">
              <img
                src={Amman}
                alt="Vivekananda Rock Memorial is located where three oceans meet the Bay of Bengal, Indian Ocean and Arabian Sea; it is located 400 meters from Kanyakumari"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">
              Arulmigu Bhagavathy Amman Aalayam
            </h2>
            <p className="mt-3 text-align-justify p">
              The Bhagavati Kumari Amman Temple is one of the 52 Shakti Peetha.
              It is believed that the back spine area of Sati’s corpse fell here
              creating the presence of Kundalini Shakti in the region. The
              shrine is accessed through the Western door. The eastern door is
              opened only on certain days of a year, as on the new moon days in
              the months of Thai, Aadi (Karkidaka) July, during Navaratri and in
              the month of Kaartikai. For the purpose of rites and rituals in
              the temple of Kumari is imagined as (Sankalpam) as Bālambika, the
              child goddess. The goddess is considered as Katyayani, one of the
              Navadurgas here. She is also considered as Bhadrakali by the
              devotees while worshipping her. Devi Kanya Kumari is the goddess
              of virginity and penance. It is a practice that people choose to
              receive the Diksha of Sanyasa from here in olden times. The rites
              and rituals of the temple are organized and classified according
              to Adi Sankara’s treatise.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KanyaKumari;
