import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLocationDot, FaMapLocation, FaPersonWalkingLuggage } from "react-icons/fa6";
import Kodaikanal_1 from "./Kodaikanal images/Amazing view of kodaikanal lake.jpeg";
import Kodaikanal_2 from "./Kodaikanal images/THALAIYAR  FALLS.jpeg";
import { TextField, Select, MenuItem } from '@mui/material';

import { TbBrandTripadvisor } from "react-icons/tb";
import { SlCalender } from "react-icons/sl";
import "./Kodaikanal.css";

const KanyaKumari = () => {
  return (
    <div>
      <div className="kodaikanal_banner">
        <h1 className="kodaikanal_banner_h1">Kodaikanal Sightseeing Places</h1>
        <center>
          <p className="kodaikanal_banner_p">Home | Kodaikanal</p>
        </center>
      </div>

      <Container>
        <Row>
          <Col>
            <center className="mt-5 mb-5">
              <h2>Kodaikanal Package Details</h2>
            </center>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td colSpan={18}>
                    <p>Kodaikanal Sight Seeing</p>
                  </td>
                  <td>
                    <p> ₹ 4500/-</p>
                  </td>
                </tr>

                <tr>
                  <td colSpan={18}>
                    <p>Limited Kilometres</p>
                  </td>
                  <td>300 km</td>
                </tr>

                <tr>
                  <td colSpan={18}>
                    <p>Intercity Extra kilometer</p>
                  </td>
                  <td>
                    <p>₹ 12/-</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <p>
            {" "}
            <strong>Notes : </strong>
            Mentioned Price Might Vary Depending On The Season, Increse in Fuel
            Prise, Weekend. Ex. : Saturday – Sunday – New Year – Deepavali Etc.
            (Please Confirm Rates Before Proceeding)i.e
          </p>
        </Row>
      </Container>

      <Container className="mb-5 p-3">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12} className="mt-5">
            <iframe
              className="iframe"
              src="https://www.youtube.com/embed/hDbjhxJL7Os?si=871AbBYgnmty8xO5"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

            <h2 className="mb-3">Best Places To Visit In Kodaikanal</h2>
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                marginLeft: "-30px",
              }}
            >
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Silver Falls
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Tirumalai Nayakar Palace
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Kodaikanal Lake
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Moyar Point
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Guna Cave
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Pine Forest
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Wax Museum
              </li>

              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Cockers Walk
              </li>

              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Liril Fall{" "}
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Chocolate Spices{" "}
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Kurinji Temple{" "}
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Chettiyar Park{" "}
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Brayant Park{" "}
              </li>
            </ul>
          </Col>

         
        <Col xxl={4} xl={4} lg={4} md={12} sm={12} xm={12} className="mt-5 ">
  <TextField
    type="text"
    label="Enter your Name*"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="Enter your Number"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="email"
    label="Enter Your Email Address"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="text"
    label="Pick Up Your Location"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <Select
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
    displayEmpty
    defaultValue=""
  >
    <MenuItem value="" disabled>Select Your Vehicle</MenuItem>
    <MenuItem value="Mini Car">Mini Car</MenuItem>
    <MenuItem value="Sedan Car">Sedan Car</MenuItem>
    <MenuItem value="SUVs Car">SUVs Car</MenuItem>
    <MenuItem value="Tempo Travelers">Tempo Travelers</MenuItem>
  </Select>
  <TextField
  type="date"
  label="Date / Time"
  variant="outlined"
  className="input"
  fullWidth
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
  <TextField
    type="number"
    label="No of Adults"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="No of Children"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    id="w3review"
    name="w3review"
    label="Enter Your Message"
    variant="outlined"
    multiline
    rows={4}
    className="input"
    fullWidth
    margin="normal"
  /> 
  <div>
  <br/>
  </div>
  
  <Button startIcon={<WhatsAppIcon />} className="whatsUp_btn">
    What's Up
  </Button>
</Col>
        </Row>
      </Container>

      {/* Vivek */}

      <Container className="mb-5">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={ Kodaikanal_1}
                alt="abu travels  kodaikanal Kodaikanal Lake"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">Kodaikanal Lake</h2>
            <p className="mt-3 text-align-justify p">
              {" "}
              Kodaikanal Lake, also known as Kodai Lake, is a manmade lake
              located in the Kodaikanal city in Dindigul district in Tamil Nadu,
              India. Sir Vere Henry Levinge, the then Collector of Madurai, was
              instrumental in creating the lake in 1863, amidst the Kodaikanal
              town which was developed by the British and early missionaries
              from USA. The lake is said to be Kodaikanal’s most popular
              geographic landmark and tourist attraction. Over the years a boat
              club, boathouse and boat service for the public and tourists has
              become fully functional and is of aesthetic significance for
              tourism. Boat Pageant and Flower Shows are a regular feature in
              the summer season which attracts tourists. Bryant Park is situated
              adjacent to the lake.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Kodaikanal_2}
                alt="Abu Travels Kodaikanal Tour Thalaiyar Falls"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">Thalaiyar Falls</h2>
            <p className="mt-3 text-align-justify p">
              Thalaiyar Falls, also known as Rat Tail Falls is a waterfall
              Located in the Theni District, which flows as Manjalar River, one
              of the tributaries of Vaigai river which flows in the south of
              Tamil Nadu State, South India. It is 975 ft tall and is the
              highest waterfall in Tamil Nadu, the Sixth highest waterfall in
              India and the 267th highest in the world.
            </p>
          </Col>
        </Row>
      </Container>


      {/*///////////// Our Process///////////// */}
   <Container className="mt-5 mb-5">
    <center>
    <p className="ephesis">Our Process</p>
    <h1>We Complete Every Step Carefully</h1>
    </center>
 
    <Row className="mt-5">
      <Col xxl={3} xl={3} lg={3} md={12} sm={12} xm={12}>
        <br />
        <center>
        <FaMapLocation  className="Process_icon p-4" /> 
        <br />
        <br />
        <h4>Choose Your Destination</h4> 
        <p>If you have a location in mind, we will gladly take you in the vehicle of your choice.</p>
        </center>
   
    
      </Col>

      <Col xxl={3} xl={3} lg={3} md={12} sm={12} xm={12}>
        <br />
        <center>
        <SlCalender className="Process_icon p-4"/> 
        <br />
        <br />
        <h4>Select The Date and Duration</h4> 
        <p>Short one-way trips or multiple plans allow us to be your all-time choice for travel.</p>
        </center>
   
    
      </Col>


      <Col xxl={3} xl={3} lg={3} md={12} sm={12} xm={12}>
        <br />
        <center>
        <FaPersonWalkingLuggage  className="Process_icon p-4"/> 
        <br />
        <br />
        <h4>Select Your Ride</h4> 
        <p>Choose at will depending on the members travelling. We have sturdy vehicles that are periodically inspected and serviced.</p>
        </center>
   
    
      </Col>

      <Col xxl={3} xl={3} lg={3} md={12} sm={12} xm={12}>
        <br />
        <center>
        <TbBrandTripadvisor  className="Process_icon p-4"/> 
        <br />
        <br />
        <h4>Enjoy Your Trip</h4> 
        <p>Our vehicles are known to make journeys smooth and cradle-like.</p>
        </center>
   
    
      </Col>
    </Row>
   </Container>
    </div>
  );
};

export default KanyaKumari;
