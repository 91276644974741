import React, { useState } from 'react';
import Logo from './Images/logo.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink, Outlet } from 'react-router-dom';
import Footer from './footer'

import './RootLayout.css'; // Import your CSS file for custom styles

const RootLayout = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  return (
    <div>
      <Navbar expand="lg" className="navbar-container">
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt="Abu Tours and Travels madurai rental cars  "
              src={Logo}
              width="250"
              height="70"
              className="d-inline-block align-top p-2"
            />{' '}
   
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleOffcanvas} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto toogle_details" style={{gap:'20px'}}>
              <Nav.Link as={NavLink} to="/" activeClassName="active" className="p-3">Home</Nav.Link>
              <Nav.Link href='#about' activeClassName="active" className="p-3">About</Nav.Link>
              <Nav.Link href='#packages' activeClassName="active" className="p-3">Packages</Nav.Link>
              <Nav.Link as={NavLink} to="/car_rental" activeClassName="active" className="p-3">Car Rental</Nav.Link>
              <Nav.Link as={NavLink} to="/tempo_traveler" activeClassName="active" className="p-3">Tempo Travelers</Nav.Link>
              <Nav.Link  href='#book_now' className="p-3">Book Now</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <Offcanvas show={showOffcanvas} onHide={toggleOffcanvas} className="navbar-container">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="offcanvasNavbarLabel">Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Nav className="ms-auto" style={{display:'block'}}>
              <Nav.Link as={NavLink} to="/" activeClassName="active" className="p-3">Home</Nav.Link>
              <Nav.Link as={NavLink} to="/about" activeClassName="active" className="p-3">About</Nav.Link>
              <Nav.Link as={NavLink} to="/packages" activeClassName="active" className="p-3">Packages</Nav.Link>
              <Nav.Link as={NavLink} to="/car_rental" activeClassName="active" className="p-3">Car Rental</Nav.Link>
              <Nav.Link as={NavLink} to="/tempo_traveler" activeClassName="active" className="p-3">Tempo Travelers</Nav.Link>
              <Nav.Link as={NavLink} to="/book_now" activeClassName="active" className="p-3">Book Now</Nav.Link>
            </Nav>
        </Offcanvas.Body>
      </Offcanvas> */}

      <main>
        <Outlet />
      </main>
      
      <Footer/>
    </div>
  );
};

export default RootLayout;
