import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLocationDot } from "react-icons/fa6";
import Kerala_1 from "./Kerala images/Munnar In Kerala - ScoopWhoop.jpeg";
import Kerala_2 from "./Kerala images/download (3).jpeg";
import { TextField, Select, MenuItem } from '@mui/material'
import "./Kerala.css";

const KanyaKumari = () => {
  return (
    <div>
      <div className="kerala_banner">
        <h1 className="kerala_banner_h1">kerala Sightseeing Places</h1>
        <center>
          <p className="kerala_banner_p">Home | kerala</p>
        </center>
      </div>

      <Container className="mb-5">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12} className="mt-5">
            <iframe
              className="iframe"
              src="https://www.youtube.com/embed/E-unkbpxaHQ?si=V9I7aF66BQYanqkW"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

            <h2 className="mb-3">Best Places To Visit In Kerala</h2>
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                marginLeft: "-30px",
              }}
            >
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Munnar
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Alappuzha
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Kochi
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Kovalam
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Wayanad
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Thiruvananthapuram
              </li>
            </ul>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xm={12} className="mt-5 ">
  <TextField
    type="text"
    label="Enter your Name*"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="Enter your Number"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="email"
    label="Enter Your Email Address"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="text"
    label="Pick Up Your Location"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <Select
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
    displayEmpty
    defaultValue=""
  >
    <MenuItem value="" disabled>Select Your Vehicle</MenuItem>
    <MenuItem value="Mini Car">Mini Car</MenuItem>
    <MenuItem value="Sedan Car">Sedan Car</MenuItem>
    <MenuItem value="SUVs Car">SUVs Car</MenuItem>
    <MenuItem value="Tempo Travelers">Tempo Travelers</MenuItem>
  </Select>
  <TextField
  type="date"
  label="Date / Time"
  variant="outlined"
  className="input"
  fullWidth
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
  <TextField
    type="number"
    label="No of Adults"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="No of Children"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    id="w3review"
    name="w3review"
    label="Enter Your Message"
    variant="outlined"
    multiline
    rows={4}
    className="input"
    fullWidth
    margin="normal"
  /> 
  <div>
  <br/>
  </div>
  
  <Button startIcon={<WhatsAppIcon />} className="whatsUp_btn">
    What's Up
  </Button>
</Col>
        </Row>
      </Container>

      {/* Vivek */}

      <Container className="mb-5">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Kerala_1}
                alt=" Munnar In Kerala - ScoopWhoop"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">Munnar</h2>
            <p className="mt-3 text-align-justify p">
              {" "}
              Munnar is a town and hill station in the Idukki district of the
              southwestern Indian state of Kerala. Munnar is situated at around
              1,600 metres above mean sea level, in the Western Ghats mountain
              range. Munnar is also called the “Kashmir of South India” and is a
              popular honeymoon destination.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Kerala_2}
                alt="Abu travels kerala Alappuzha"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">Alappuzha</h2>
            <p className="mt-3 text-align-justify p">
              Alappuzha or Alleppey is the administrative headquarters of
              Alappuzha district in state of Kerala, India. The Backwaters of
              Alappuzha are one of the most popular tourist attractions in India
              which attracts millions of domestic and international tourists.
              Alleppey is a city and a municipality in Kerala with an urban
              population of 174,164 and ranks third among the districts in
              literacy rate in the state. In 2016, the Centre for Science and
              Environment rated Alappuzha as the cleanest town in India.
              Alappuzha is considered to be the oldest planned city in this
              region and the lighthouse built on the coast of the city is the
              first of its kind along the Laccadive Sea coast. The city is 55 km
              from Kochi and 155 km north of Thiruvananthapuram. A town with
              canals, backwaters, beaches, and lagoons, Alappuzha was described
              by George Curzon, Viceroy of India at the start of the 20th
              century, as the “Venice of the East.” Hence, it is known as the
              “Venetian Capital” of Kerala. It is an important tourist
              destination in India. It connects Kumarakom and Cochin to the
              North and Kollam to the South
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KanyaKumari;
