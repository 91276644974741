import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLocationDot } from "react-icons/fa6";
import Madurai_1 from "./Madurai images/Meenakshi Amman Temple, Madurai, Tamil Nadu.jpeg";
import Madurai_2 from "./Madurai images/licensed-image-3.jpg";
import { TextField, Select, MenuItem } from '@mui/material';
import "./Madurai.css";

const KanyaKumari = () => {
  return (
    <div>
      <div className="madurai_banner">
        <h1 className="madurai_banner_h1">Madurai Sightseeing Places</h1>
        <center>
          <p className="madurai_banner_p">Home | Madurai</p>
        </center>
      </div>

      <Container>
        <Row>
          <Col>
            <center className="mt-5 mb-5">
              <h2>Madurai Package Details</h2>
            </center>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td colSpan={18}>
                    <p>Madurai Sight Seeing</p>
                  </td>
                  <td>
                    <p> ₹ 2500/-</p>
                  </td>
                </tr>

                <tr>
                  <td colSpan={18}>
                    <p>Limited Kilometres</p>
                  </td>
                  <td>100 km</td>
                </tr>

                <tr>
                  <td colSpan={18}>
                    <p>Intercity Extra kilometer</p>
                  </td>
                  <td>
                    <p>₹ 12/-</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <p>
            {" "}
            <strong>Notes : </strong>
            Mentioned Price Might Vary Depending On The Season, Increse in Fuel
            Prise, Weekend. Ex. : Saturday – Sunday – New Year – Deepavali Etc.
            (Please Confirm Rates Before Proceeding)i.e
          </p>
        </Row>
      </Container>

      <Container className="mb-5 p-3" >
        <Row >
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12} className="mt-5">
            <iframe
              className="iframe"
              src="https://www.youtube.com/embed/r5cKoORM4_0?si=U5mytZTo37y_bDev"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

            <h2 className="mb-3">Best Places To Visit In Madurai</h2>
            <ul style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Meenakshi Temple
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Tirumalai Nayakar Palace
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Alagar Temple
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Tirumalai nayakar palace
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Gandhi musiam
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Tiruparnkundrm temple
              </li>
              <li style={{ listStyle: "none" }}>
                <FaLocationDot /> Theppakulam lake
              </li>
            </ul>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xm={12} className="mt-5 ">
  <TextField
    type="text"
    label="Enter your Name*"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="Enter your Number"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="email"
    label="Enter Your Email Address"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="text"
    label="Pick Up Your Location"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <Select
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
    displayEmpty
    defaultValue=""
  >
    <MenuItem value="" disabled>Select Your Vehicle</MenuItem>
    <MenuItem value="Mini Car">Mini Car</MenuItem>
    <MenuItem value="Sedan Car">Sedan Car</MenuItem>
    <MenuItem value="SUVs Car">SUVs Car</MenuItem>
    <MenuItem value="Tempo Travelers">Tempo Travelers</MenuItem>
  </Select>
  <TextField
  type="date"
  label="Date / Time"
  variant="outlined"
  className="input"
  fullWidth
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
  <TextField
    type="number"
    label="No of Adults"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="No of Children"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    id="w3review"
    name="w3review"
    label="Enter Your Message"
    variant="outlined"
    multiline
    rows={4}
    className="input"
    fullWidth
    margin="normal"
  /> 
  <div>
  <br/>
  </div>
  
  <Button startIcon={<WhatsAppIcon />} className="whatsUp_btn">
    What's Up
  </Button>
</Col>
        </Row>
      </Container>

      {/* Vivek */}

      <Container>
        <Row >
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Madurai_1}
                alt="Vivekananda Rock Memorial is located where three oceans meet the Bay of Bengal, Indian Ocean and Arabian Sea; it is located 400 meters from Kanyakumari"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">Meenakshi Amman Temple</h2>
            <p className="mt-3 text-align-justify p">
            
              Arulmigu Meenakshi Sundaraswarar Temple is a historic Hindu temple
              located on the southern bank of the Vaigai River in the temple
              city of Madurai, Tamil Nadu, India. It is dedicated to the goddess
              Meenakshi, a form of Parvati, and her consort, Sundareshwarar, a
              form of Shiva. The temple is at the center of the ancient temple
              city of Madurai mentioned in the Tamil Sangam literature, with the
              goddess temple mentioned in 6th-century-CE texts. This temple is
              one of the Paadal Petra Sthalams. The Paadal Petra sthalams are
              275 temples of lord Shiva that are revered in the verses of Tamil
              Saiva Nayanars of the 6th-9th century CE. The west tower of the
              temple is the model based on which the Tamil Nadu State Emblem is
              designe
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xm={12}>
            <div className="vivek_image">
              <img
                src={Madurai_2}
                alt="Vivekananda Rock Memorial is located where three oceans meet the Bay of Bengal, Indian Ocean and Arabian Sea; it is located 400 meters from Kanyakumari"
                className="vivek_image"
              />
            </div>
            <h2 className="mt-3 text-align-justify">Thiruparankundram</h2>
            <p className="mt-3 text-align-justify p">
              Thiruparankundram, also spelled Tirupparankundram or
              Tiruparangundram, is a town in Madurai district in Tamil Nadu,
              India. It is about 7 kilometres from Madurai city and constitutes
              the southwest part of the greater Madurai city. The town’s
              landmark and fame is the huge monolithic rock hill that towers to
              a height of 1,048 feet and has a circumference of over 2 miles. It
              is a home to many ancient and historic monuments, some protected
              as India’s national monuments. As of 2011, the town had a
              population of 48,810. It was incorporated into a new township in
              2011, and the first local body election for the corporation was
              held on 18 October 2011.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KanyaKumari;
