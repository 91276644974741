import React, { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import { TextField, Select, MenuItem } from '@mui/material';
import Arrow_image from './Images/arrow.png'
import Arrow_image_2 from './Images/arrow-2.png'
import Video from "./Video 2/Welcome to India.mp4";
import Video_2 from "./Video 2/Welcome to India.mp4";
import VisibilitySensor from "react-visibility-sensor";
import Image_1 from "./Home Images/madurai banner.jpg";
import About_image from './Images/DEL.png'
import Image_2 from "./Home Images/pamban  bridge.jpg";
import Image_3 from "./Home Images/Kerala-Tourism-01.webp";
import Image_4 from "./Home Images/5.webp";
import Thanjai from "./Home Images/two-men-in-helmets-ride-on-atv-action-view-1-e1644355840783-1.jpg";
import Kerala from "./Home Images/germany-bavaria-faistenberg-family-on-a-bicycle-tour-e1645667990614-1024x668-2.jpg";
import Madurai from "./Home Images/white-water-rafting-800x1200-2-683x1024.jpg";
import Meen from "./Home Images/meenakshi-temple.jpg";
import Kodaikanal from "./Home Images/Kodaikanal-1.jpg";
import Rameshwaram from "./Home Images/Rameshwaram (1).jpg";
import Kannyakumari from "./Home Images/Kanyakumari.jpg";
import Palani from "./Home Images/palani-1.jpg";
import { TiTick } from "react-icons/ti";
import { FaAward } from "react-icons/fa6";
import { FaUserGroup } from "react-icons/fa6";
import { GrCertificate } from "react-icons/gr";
import { GrGroup } from "react-icons/gr";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CountUp from "react-countup";
import Car_1 from "./Home Images/Suzuki-Ertiga-fronw.jpg";
import Car_2 from "./Home Images/innova.jpg";
import Car_3 from "./Home Images/swift-dzire.jpg";
import why_choose_image from "./Home Images/why choose us.jpg";
import { RiCarWashingFill } from "react-icons/ri";
import { GrMoney } from "react-icons/gr";
import { Ri24HoursFill } from "react-icons/ri";
import { TfiMapAlt } from "react-icons/tfi";
import { FaHotel } from "react-icons/fa";
import { FaBusAlt } from "react-icons/fa";
import { FaMapLocation } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { TbBrandTripadvisor } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./Home.css";
import { Link } from "react-router-dom";

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [value, setValue] = React.useState(5);

  return (
    <div>
<div className="carousel mb-5">
<Carousel slide={true} wrap={true} controls={false} >
 <Carousel.Item interval={3000}>
        <img
           className="d-block w-100 Carousel_images"
          src={Image_1}
        
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
           className="d-block w-100 Carousel_images"
          src={Image_2}
      
          alt="abu travels madurai"
        />
      
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100 Carousel_images"
          src={Image_3}
          
          alt="abu travels madurai"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
        className="d-block w-100 Carousel_images"
          src={Image_4}
          
          alt="abu travels madurai"
        />
      
      </Carousel.Item>

    </Carousel>

    <div className="carousel_details "  >
          <div className="carousel_details_container">
            <Container>
              <Row  >
                <Col  className="carousel_details_h1_col"  >
                  <div className="carosel_details_div_h1_container">
                  <h1 className="carousel_details_h1 p-2">CAR FOR RENT IN MADURAI</h1>
                  </div>
                
                </Col>
              </Row>
            </Container>
           
           <Container > 
            <Row>
              <Col  >
              <Container className="">
                <Row>
                  <Col>
                  <p className="carousel_details_p p-3 mt-3" >
              Abu Tours and Travels is a professional team with over 10 years of
              experience in travel-related services and offers the best
              customized deals for car rentals, tour packages and memorable
              travel experience.
            </p>
                  </Col>
                </Row>
             
              </Container>
            
            </Col>
            </Row>
            </Container>
            <br />
            <div className="home_btns"><a href="#packages"></a>
              <button className="home_btn home_btn_1 p-3 mb-3">
                Tour Package
              </button>
              <button className="Book_btn Book_btn_1 p-3 mb-3">Book Now</button>
            </div>
          </div>
        </div>
    </div>

    <br /><br />



      {/* <video src={Video} autoPlay loop muted className="video">
        <div className="carousel_details">
          <div className="carousel_details_container">
            <h1 className="carousel_details_h1 ">CAR FOR RENT IN MADURAI</h1>
            <br />
            <p className="carousel_details_p p-5">
              Abu Tours and Travels is a professional team with over 10 years of
              experience in travel-related services and offers the best
              customized deals for car rentals, tour packages and memorable
              travel experience.
            </p>{" "}
            <br />
            <div className="home_btns"><a href="#packages"></a>
              <button className="home_btn home_btn_1 p-3 mb-3">
                Tour Package
              </button>
              <button className="Book_btn Book_btn_1 p-3 mb-3">Book Now</button>
            </div>
          </div>
        </div>
      </video>

      <div className="carousel_details">
        <div className="">
          <h1 className="carousel_details_h1 ">CAR FOR RENT IN MADURAI</h1>
          <br />
          <p className="carousel_details_p ">
            Abu Tours and Travels is a professional team with over 10 years of
            experience in travel-related services and offers the best customized
            deals for car rentals, tour packages and memorable travel
            experience.
          </p>{" "}
          <br />
          <div className="home_btns">
            <button className="home_btn home_btn_1 p-3 mb-3">
              Tour Package
            </button>
            <button className="Book_btn Book_btn_1 p-3 mb-3">Book Now</button>
          </div>
        </div>
      </div> */}

      {/* Welcome To Abu Tours & Travels */}

      <Container className="mt-5 Welcome_To_Abu mt-5 px-4" id="about">
        <Row>
          <Col xxl={6} xl={6} lg={6} md={12} sx={12} xs={12}>
            <div className="big_image">
              <img src={About_image} alt="abu travels madurai" className="big_image_thanjai del mb-4" />
            </div>
           
          </Col>

          <Col xxl={6} xl={6} lg={6} md={12} sx={12} xs={12} >
            <p className="ephesis">Welcome To Abu Tours & Travels</p>
            <h1 className="mb-3">We Are The Best Tours & Travels in Madurai</h1>
            <p  >
              Abu Tours And Travels has been trusted by hundreds of happy
              travellers for several decades now as a renowned and acclaimed as
              the best travel service provider based out of Madurai.
            </p>
            <Row className="mt-5">
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xm={12}>
                <p>
                  <TiTick className="tick_icon" /> Best Award
                </p>
                <p>
                  <TiTick className="tick_icon" /> Already Certified
                </p>
                <p>
                  <TiTick className="tick_icon" /> Professional Team
                </p>
                <p>
                  <TiTick className="tick_icon" /> Complete Equipment
                </p>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xm={12}>
                <p>
                  <TiTick className="tick_icon" /> Experience
                </p>
                <p>
                  <TiTick className="tick_icon" /> Best Safety
                </p>
                <p>
                  <TiTick className="tick_icon" /> Facilities & Services
                </p>
                <p>
                  <TiTick className="tick_icon" /> Money Back Guarantee
                </p>
              </Col>
            </Row>
            <button className="home_btn home_btn_1 p-3 mt-5">About Us</button>
          </Col>
        </Row>
      </Container>

      {/* Packages */}

      <Container className="mt-5" id="packages">
        <center>
          <p className="ephesis">Packages</p>
          <h1>Popular Packages For You</h1>
        </center>
        <Row className="justify-content-center">
          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xm={12}
            className="mt-2 mb-2 p-4"
          >
            <div className="home_page-card_container_col ">
              <div className="home_page-card_container">
                <img src={Meen} alt="" className="home_page-card_image " />
              </div>
              <div className="card_lists p-2 mt-2">
              
             
                  <h5 className="ms-2 mb-3">Madurai Sightseeing Places</h5>
               
            
                <ol >
                  <li>Meenakshi temple</li>
                  <li>Alagar temple</li>
                  <li>Tirumalai nayakar palace</li>
                  <li>Gandhi musiam</li>
                  <li> Theppakulam lake</li>
                </ol>
              
                <Link to='madurai' className="">
                  <button className="home_btn book_now-btn home_btn_1 mb-3 ms-3 mt-4  py-2 px-4">
                    Book Now
                  </button>
                  </Link>
                  
               
              </div>
            </div>
          </Col>

          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xm={12}
            className="mt-2 mb-2 p-4"
          >
            <div className="home_page-card_container_col">
              <div className="home_page-card_container">
                <img
                  src={Kodaikanal}
                  alt="Kodaikanal"
                  className="home_page-card_image "
                />
              </div>
              <div className="card_lists p-2 mt-2">
             
               
                  <h5 className="ms-3 mb-3">Kodaikanal Sightseeing Places</h5>
               
                
                <div className="kodaikanak_lists  ">
                  <div className="list_one">
                    <ol >
                      <li>Silver falls</li>
                      <li>Lake Kodaikanal</li>
                      <li>Moyar point</li>
                      <li>Guna cave</li>
                      <li>Pine forest</li>
                      <li>Wax museum</li>
                    </ol>
                  </div>

                  <div className="list_two">
                    <ol start={7} >
                      <li>Cockers walk</li>
                      <li>Liril falls</li>
                      <li>Chocolate spices</li>
                      <li>Kurinji temple</li>
                      <li>Chettiyar park</li>
                      <li>Brayant park</li>
                    </ol>
                  </div>
                </div>

               
                  <Link to='kodaikanal'>
                  <button className="home_btn book_now-btn home_btn_1 mb-3 ms-3 mt-2  py-2 px-4">
                    Book Now
                  </button>
                  </Link>
                
              </div>
            </div>
          </Col>

          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xm={12}
            className="mt-2 mb-2 p-4"
          >
            <div className="home_page-card_container_col">
              <div className="home_page-card_container">
                <img
                  src={Rameshwaram}
                  alt=""
                  className="home_page-card_image "
                />
              </div>
              <div className="card_lists p-2 mt-2">
           
                <center>
                  <h5 className="mb-3">Rameswaram Sightseeing Places</h5>
                </center>
              
                <ol >
                  <li>Ramanatha swamy temple</li>
                  <li>Pamban bridge</li>
                  <li>Ramar & Lakshmana kund</li>
                  <li>Floating stone</li>
                  <li>Thanushkodi</li>
                  <li>Abdul Kalam memorial</li>
                </ol>
            
               
                  <Link to='rameshwaram'>
                  <button className="home_btn book_now-btn home_btn_1 mb-3 ms-3 mt-1  py-2 px-4">
                    Book Now
                  </button>
                  </Link>
            
              </div>
            </div>
          </Col>

          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xm={12}
            className="mt-2 mb-2 p-4"
          >
            <div className="home_page-card_container_col">
              <div className="home_page-card_container">
                <img
                  src={Kannyakumari}
                  alt=""
                  className="home_page-card_image "
                />
              </div>
              <div className="card_lists p-2">
                
                <center>
                  <h5 className="mb-2">Kanyakumari Sightseeing Places</h5>
                </center>
               
                <ol >
                  <li>Sunrise point</li>
                  <li>Sunset point</li>
                  <li>Pagavathi Amman temple</li>
                  <li>Vivekananda memorial</li>
                  <li>Tiruvalluvar statue</li>
                  <li>Suchintram temple</li>
                </ol>
            
               
                  <Link to='kanyakumari'>
                  
                  <button className="home_btn book_now-btn home_btn_1 mb-3 ms-3 mt-2  py-2 px-4">
                    Book Now
                  </button>
                  </Link>
               
              </div>
            </div>
          </Col>

          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xm={12}
            className="mt-2 mb-2 p-4"
          >
            <div className="home_page-card_container_col">
              <div className="home_page-card_container">
                <img src={Palani} alt="" className="home_page-card_image " />
              </div>
              <div className="card_lists p-2">
        
               
                  <h5 className="ms-3 mb-3">Palani Sightseeing Places</h5>
            
               
                <ol >
                  <li>Palani Hills</li>
                  <li>Lord Murugan Temple</li>
                  <li>Idumban Temple</li>
                  <li>Shri Periya Nayaki Amman Temple</li>
                  <li>Arulmigu Dhandayuthapani Swamy Temple</li>
                  <li>Golden chariot</li>
                </ol>
      
            
                  <Link to='palani'>
                  <button className="home_btn book_now-btn home_btn_1 mb-3 ms-3 mt-1  py-2 px-4">
                    Book Now
                  </button>
                  </Link>
           
              </div>
            </div>
          </Col>

          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xm={12}
            className="mt-2 mb-2 p-4"
          >
            <div className="home_page-card_container_col">
              <div className="home_page-card_container">
                <img src={Kerala} alt="" className="home_page-card_image " />
              </div>
              <div className="card_lists p-2">
        
              
                  <h5 className="mb-3 ms-3">Kerala Sightseeing Places</h5>
             
        
                <ol>
                  <li>Munnar</li>
                  <li>Alappuzha</li>
                  <li>Kochi</li>
                  <li>Kovalam</li>
                  <li>Wayanad</li>
                  <li>Thiruvananthapuram</li>
                </ol>
           
                
                  <Link to='kerala'>
                  <button className="home_btn book_now-btn home_btn_1 mb-3 ms-3 mt-1  py-2 px-4">
                    Book Now
                  </button>
                  </Link>
             
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/*//////////////////// Counter ///////////*/}
      <div className="counter_container_div">
        <Container className="p-3">
          <Row className="mt-5 ">
            <Col xxl={3} xl={3} lg={3} md={3} sm={12} xm={12} className="mb-5">
              <div className="counter_section">
                <center>
                  <FaAward className="counter_icons mb-2" /> <br />
                  <VisibilitySensor
                    className="count_number"
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setIsVisible(true);
                      }
                    }}
                  >
                  
                    <CountUp
                      duration={10}
                      className="counter mt-2"
                      end={isVisible ? 100 : 0}
                    />
                  </VisibilitySensor>
                  <span className="counter_symbol mt-2">%</span>
                  
                  
                  <p className="counter_icons_p mt-2">Service Guarante</p>
                </center>
              </div>
            </Col>

            <Col xxl={3} xl={3} lg={3} md={3} sm={12} xm={12} className="mb-5">
              <div className="counter_section">
                <center>
                  <FaUserGroup className="counter_icons mb-2" /> <br />
                  <VisibilitySensor
                    className="count_number"
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setIsVisible(true);
                      }
                    }}
                  >
                    <CountUp
                     duration={10}
                      className="counter mt-2"
                      end={isVisible ? 287 : 0}
                    />
                  </VisibilitySensor>
                  <span className="counter_symbol">+</span>
                  <p className="counter_icons_p mt-2">Happy Customer</p>
                </center>
              </div>
            </Col>

            <Col xxl={3} xl={3} lg={3} md={3} sm={12} xm={12} className="mb-5">
              <div className="counter_section">
                <center>
                  <GrCertificate className="counter_icons mb-2" /> <br />
                  <VisibilitySensor
                    className="count_number"
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setIsVisible(true);
                      }
                    }}
                  >
                    <CountUp
                      duration={10}
                      className="counter mt-2"
                      end={isVisible ? 99 : 0}
                    />
                  </VisibilitySensor>
                  <span className="counter_symbol">%</span>
                  <p className="counter_icons_p mt-1">Certified</p>
                </center>
              </div>
            </Col>

            <Col xxl={3} xl={3} lg={3} md={3} sm={12} xm={12} className="mb-5">
              <div className="counter_section">
                <center>
                  <GrGroup className="counter_icons mb-2 " /> <br />
                  <VisibilitySensor
                    className="count_number"
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setIsVisible(true);
                      }
                    }}
                  >
                    <CountUp
                      duration={10}
                      className="counter mt-2"
                      end={isVisible ? 40 : 0}
                    />
                  </VisibilitySensor>
                  <span className="counter_symbol">+</span>
                  <p className="counter_icons_p">Professional Team</p>
                </center>
              </div>
            </Col> 
            <div>
              <br />
         
            </div>
          </Row>
        </Container>
      </div>

      {/* /////////////// Car Rentals ///////////////////////////*/}

      <Container className="mt-5 mb-5">
      <center>
          <p className="ephesis">Car Rentals</p>
          <h1>Our Popular Vehicles</h1>
        </center>
        <Row className="mt-5">
          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={12}
            sm={12}
            xm={12}
            style={{ marginright: "10px" }}
            className="mb-2 p-4"
          >
            <div className="home_Car_rental_details">
              <div className="home-car_rental_col">
                <div className="home_package_car_renttal_div_container">
                  <div className="home_car_rental_image_container">
                    <img
                      src={Car_2}
                      alt="car rental in madurai"
                      className="home_car_rental_image mt-2 p-3"
                    />
                  </div>
                </div>
                <br />

                <center>
                  <h3 className="mt-2">INNOVA</h3>
           
                  <p  className="rental_car_p">Round Trip Rs 17</p>
                  <p  className="rental_car_p">Driver Allowance Rs. 500/-</p>
                  <p className="rental_car_p">Round Trip Minimum/ 300 Km</p>
                </center>
                <div className="home_btns">
                  <center>
                    <button className="home_btn home_btn_1 p-3 mb-3">
                      More details
                    </button>
                    <button className=" More_details_btn p-3  mb-3">
                      Book Now
                    </button>
                  </center>
                  <br />
                </div>
              </div>
            </div>
          </Col>

          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={12}
            sm={12}
            xm={12}
            style={{ marginright: "10px" }}
            className="mb-2 p-4"
          >
            <div className="home-car_rental_col">
              <div className="home_package_car_renttal_div_container">
                <div className="home_car_rental_image_container">
                  <img
                    src={Car_1}
                    alt="car rental in madurai"
                    className="home_car_rental_image  p-3"
                  />
                </div>
              </div>

              <div className="home_Car_rental_details">
           
                <center>
                  <h3 className="mt-2">XYLO, TAVERA, ENJOY</h3>
                
                  <p  className="rental_car_p">Round Trip Rs 16</p>
                  <p  className="rental_car_p">Driver Allowance Rs. 400/-</p>
                  <p  className="rental_car_p" >Round Trip Minimum/ 300 Km</p>
                </center>
                <div className="home_btns">
                  <center>
                    <button className="home_btn home_btn_1 p-3 mb-3">
                      More details
                    </button>
                    <button className=" More_details_btn p-3  mb-3">
                      Book Now
                    </button>
                  </center>
                </div>
                <br />
              </div>
            </div>
          </Col>

          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={12}
            sm={12}
            xm={12}
            style={{ marginright: "10px" }}
            className="mb-2 p-4"
          >
            <div className="home-car_rental_col">
              <div className="home_package_car_renttal_div_container">
                <div className="home_car_rental_image_container">
                  <img
                    src={Car_3}
                    alt="car rental in madurai"
                    className="home_car_rental_image p-3 mb-3"
                  />
                </div>
              </div>

              <div className="home_Car_rental_details">
             
                <center>
                  <h3 className="mt-2">ETIOS, DZIRE, XCENT</h3>
               
                  <p className="rental_car_p">Round Trip Rs 13</p>
                  <p  className="rental_car_p">Driver Allowance Rs. 300/-</p>
                  <p  className="rental_car_p"> Round Trip Minimum/ 250 Km</p>
                </center>
                <div className="home_btns rental_car_home_btns">
                  <center>
                    <button className="home_btn home_btn_1 p-3 mb-3">
                      More details
                    </button>
                    <button className=" More_details_btn p-3  mb-3">
                      Book Now
                    </button>
                  </center>
                </div>
                <br />
                <br />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* //////////////////Why Choose Us ///////////////*/}

      <Container fluid className="mt-5 mb-5">
        <Row>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
            <div className="why_choose_us_image_conatiner">
              <img
                src={why_choose_image}
                alt="abu travels madurai why_choose_us_image"
                className="why_choose_us_image"
              />
            </div>
          </Col>

          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xm={12}
            className="why_choose_us_details_conatiner_col p-5 "
          >
            <div className="why_choose_us_details_conatiner">
              <p className="why">Why Choose Us</p>
              <h1 className="why_choose_us_h1">We are Professional Planners for Your Vacations</h1>
            
              <p className="why_choose_us_p">
                Abu Tours and Travels focuses on providing professional travel
                solutions in Coimbatore. After years of understanding the travel
                business and dealing with various client issues, we have one of
                the largest car and bus networks and services with a personal
                touch. As you travel, we intend to give you the best we have to
                offer.
              </p>
            </div>
            <Row>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
                <h3 className="why_choose_us_h3">
                  <RiCarWashingFill className="tick_icon me-2" /> Best Accommodation
                </h3>
                <p style={{ marginLeft: "48px" }} className="why_choose_us_p">
                  We offer a host of travel services designed to make business
                  and leisure travel easier.
                </p>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
                <h3 className="why_choose_us_h3">
                  <GrMoney className="tick_icon me-2" /> Competitive Price
                </h3>
                <p style={{ marginLeft: "48px" }} className="why_choose_us_p">
                  We offer a host of travel services designed to make business
                  and leisure travel easier.
                </p>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
                <h3 className="why_choose_us_h3">
                  <Ri24HoursFill className="tick_icon me-2" /> Support 24/7
                </h3>
                <p style={{ marginLeft: "48px" }} className="why_choose_us_p">
                  We are within easy reach, or can be said, just a click away
                  from you. For assistance.
                </p>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
                <h3 className="why_choose_us_h3 ">
                  <TfiMapAlt className="tick_icon me-2" /> Beautiful Destination
                </h3>
                <p style={{ marginLeft: "48px" }} className="why_choose_us_p">
                  Find well-maintained Cabs like Dzire Rentals, Etios Hire,
                  Innova for rentals.
                </p>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
                <h3 className="why_choose_us_h3">
                  <FaHotel className="tick_icon me-2" /> Luxury Hotel
                </h3>
                <p style={{ marginLeft: "48px" }} className="why_choose_us_p">
                  After years of understanding the travel business and dealing
                  with various client issues.
                </p>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
                <h3 className="why_choose_us_h3">
                  <FaBusAlt className="tick_icon me-2" /> Best Travel Vehicle
                </h3>
                <p style={{ marginLeft: "48px" }} className="why_choose_us_p">
                  Innova for hire or rentals, 29-33 seater Buses Rentals, 13
                  seater Tempo Travellers Hire.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/*///////////// Our Process///////////// */}
      <Container>
        <center>
          <p className="ephesis">Our Process</p>
          <h1>We Complete Every Step Carefully</h1>
        </center>

        <Row className="mt-5">
          <Col xxl={3} xl={3} lg={3} md={12} sm={12} xm={12} className="our_process_col_1">
            <div className="our_process_col  arrow_parent p-3 col-lg ">
            <br />
            <center>
              <FaMapLocation className="Process_icon p-4" />
              <br />
              <br />
              <h5>Choose Your <br /> Destination</h5>
              <p className="our_process_p">
                If you have a location in mind, we will gladly take you in the
                vehicle of your choice.
              </p>
            </center>
            <div className="arrow_child">
<img src={Arrow_image} alt="abu travels" className="arrow_image" />
            </div>
            </div>
          </Col>

          <Col xxl={3} xl={3} lg={3} md={12} sm={12} xm={12} className="our_process_col_2">
            <div  className="our_process_col  arrow_2_parent our_process_enjoy  p-3 col-lg mt-5">
            <br />
            <center>
              <SlCalender className="Process_icon p-4" />
              <br />
              <br />
              <h5>Select The Date <br /> and Duration</h5>
              <p className="our_process_p">
                Short one-way trips or multiple plans allow us to be your
                all-time choice for travel.
              </p>
            </center>
            <div className="arrow_image_2">
              <img src={Arrow_image_2} alt="abu travels madurai" className="arrow_2_child" />
            </div>
            </div>
          </Col>

          <Col xxl={3} xl={3} lg={3} md={12} sm={12} xm={12} className="our_process_col_3">
            <div className="our_process_col  p-3 arrow_parent col-lg " >
            <br />
            <center>
              <FaPersonWalkingLuggage className="Process_icon p-4" />
              <br />
              <br />
              <h5 className="our_process_h5">Select Your <br /> Ride</h5>
              <p className="our_process_p">
                Choose at will depending on the members travelling. We have
                sturdy vehicles that are periodically inspected and serviced.
              </p>
            </center>
            <div className="arrow_child">
<img src={Arrow_image} alt="abu travels" className="arrow_image" />
            </div>
            </div>
          </Col>

          <Col xxl={3} xl={3} lg={3} md={12} sm={12} xm={12} className="our_process_col_4">
            <div className="our_process_col  our_process_enjoy  p-3 col-lg mt-1">
            <br />
            <center>
              <TbBrandTripadvisor className="Process_icon p-4" />
              <br />
              <br />
              <h5>Enjoy Your <br /> Trip</h5>
              <p className="our_process_p">
                Our vehicles are known to make journeys smooth and cradle-like.
              </p>
            </center>
            <br />
           
            </div>
          </Col>
        </Row>
      </Container>

      {/* Testimonials */}
      <div className="testimonial_container">
        <Container className="mt-5 p-4">
          <center>
            <p className="ephesis">Testimonials</p>

            <h1>What Our Traveller Says</h1>
          </center>
          <br />
          <Row>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xm={12}
              className="mb-3 mt-3"
            >
              <div className="testimonial_card_container">
                <br />
                <br />
                <center>
                  <CgProfile className="restimonial_icon" />
                  <br />
                  <br />
                  <Rating name="read-only" value={value} readOnly />
                  <br />
                  <br />
                  <p style={{textAlign:'justify'}} className="p-3">
                    Went to kodaikanal for a day trip with Abu tours and
                    travels. Very punctual and friendly travel experience
                    overall. Would recommend to anyone who wants to explore by
                    car.
                  </p>
                  <br />
                  <h3>Sanjay</h3>
                  <p>Traveler</p>
                </center>
                <br />
              </div>
            </Col>

            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xm={12}
              className="mb-3 mt-3"
            >
              <div className="testimonial_card_container">
                <br />
                <br />
                <center>
                  <CgProfile className="restimonial_icon" />
                  <br />
                  <br />
                  <Rating name="read-only" value={value} readOnly />
                  <br />
                  <br />
                  <p style={{textAlign:'justify'}} className="p-3">
                    Abu Tours & Travels Get your comfortable journey for
                    outstations Taki services at your doorstop Book your ride
                    with us Love your time take a taxi..
                  </p>
                  <br />
                  <h3>Priya</h3>
                  <p>Traveler</p>
                </center>
                <br />
                <br />
              </div>
            </Col>

            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xm={12}
              className="mb-3 mt-3"
            >
              <div className="testimonial_card_container">
                <br />
                <br />
                <center>
                  <CgProfile className="restimonial_icon" />
                  <br />
                  <br />
                  <Rating name="read-only" value={value} readOnly />
                  <br />
                  <br />
                  <p style={{textAlign:'justify'}} className="p-3">
                    Safe and good people to travel with. Drivers with patience
                    and knowledge about locality where we plan to travel is
                    added advantage. Do share if anyone wants.
                  </p>
                  <br />
                  <h3>Mohan</h3>
                  <p>Traveler</p>
                </center>
                <br />
                <br />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* .Get In Touch */}

      <Container className="mt-5" id="book_now" >
        <Row>
          <center>
            <p className="ephesis">Get In Touch</p>
            <h1>Book Your Trip</h1>
          </center>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
  <div className="map_iframe p-1 mt-5" >
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d503014.15652671846!2d78.147984!3d9.95075!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c680d918d067%3A0xacf1aa4b03b7f69a!2sK.Pudur%20E1%20Police%20station%2C%20Madurai%20City!5e0!3m2!1sen!2sus!4v1710757917213!5m2!1sen!2sus"
     className="map_iframe mt-3"
      style={{ border: '0' }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</Col>


<Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12} className="mt-5">
  <TextField
    type="text"
    label="Enter your Name*"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="Enter your Number"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="email"
    label="Enter Your Email Address"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="text"
    label="Pick Up Your Location"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <Select
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
    displayEmpty
    defaultValue=""
  >
    <MenuItem value="" disabled>Select Your Vehicle</MenuItem>
    <MenuItem value="Mini Car">Mini Car</MenuItem>
    <MenuItem value="Sedan Car">Sedan Car</MenuItem>
    <MenuItem value="SUVs Car">SUVs Car</MenuItem>
    <MenuItem value="Tempo Travelers">Tempo Travelers</MenuItem>
  </Select>
  <TextField
  type="date"
  label="Date / Time"
  variant="outlined"
  className="input"
  fullWidth
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
  <TextField
    type="number"
    label="No of Adults"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    type="number"
    label="No of Children"
    variant="outlined"
    className="input"
    fullWidth
    margin="normal"
  />
  <TextField
    id="w3review"
    name="w3review"
    label="Enter Your Message"
    variant="outlined"
    multiline
    rows={4}
    className="input"
    fullWidth
    margin="normal"
  /> 
  <div>
  <br/>
  </div>
  
  <Button startIcon={<WhatsAppIcon />} className="whatsUp_btn">
    What's Up
  </Button>
</Col>
        </Row>
      </Container>

      {/* Get 30% Discount */}
      <div className="discount_2_container mt-5 ">
        
       
          <div className="discount_details_container p-5">
            <Container className="mt-5">
              <Row className="discount_row">
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xm={12}>
                  <h1 className="Start_Planning">
                    Start Planning Your <br /> Trip Now and 
                    <span className="discount"> Get 30% Discount</span>
                  </h1>
                  <br />
                  <p className="discount_p">
                    Book with Abu Tours and Travels. We are A User-Centric
                    Vehicle Rental Company.
                  </p>
                 
                  <p>
                    Ready to embark on your next adventure? Book with Abu Tours
                    and Travels today and let us take care of the rest.
                  </p>
                  <br />
                <a href="#book_now">  <button className="home_btn discount_btn home_btn_1 p-3 mb-3">
                    {" "}
                    Book Your Trip
                  </button></a>
                </Col>

              </Row>
            </Container>
          </div>
      
      </div>
    </div>
  );
};

export default Home;
