import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Gallery from "./Tempo_rental_banner Images/5f48ac0fbfac2.jpg";
import { FaBusAlt } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaLocationArrow } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import Accordion from "react-bootstrap/Accordion";
import "./Tempo_travels.css";

const Car_rentals = () => {
  return (
    <div>
      <div className="Tempo_rental_banner">
        <h1 className="Tempo_rental">Tempo Traveller</h1>
      </div>
      <br />
      <br />
      <Container fluid>
        <Row className="mt-5">
          <Col>
            <center>
              <h1>Madurai Tempo Traveller</h1>

              <p >We provide the service rent a car in madurai with driver</p>
              <br />
              <p className="all_p p-2">
                Our car rental company has been operating in India, offering a
                wide range of cars for rent in Maduri to suit your needs. every
                need and budget. Our fleet includes everything from compact
                hatchbacks and sedans to spacious SUVs and luxury cars, all
                well-maintained and equipped with the latest amenities. We pride
                ourselves on providing exceptional customer service. and
                flexible rental options, including daily, weekly, and monthly
                rentals. Our team of dedicated professionals is always ready to
                assist you with your car rental needs , whether you’re going on
                a road trip or attending a special event. event, or need a
                reliable mode of transportation for business. or other personal
                use. Our goal is to make your car rental experience seamless.
                and enjoyable as possible, so you can focus on your travels. and
                make the most of your time in India. Choose us for all your car
                rental needs and experience the best in comfort, convenience and
                value . Our one of the best service is innova car rental in
                Madurai.
              </p>
            </center>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xm={12}
            className="left_side_col p-5"
          >
            <h3 className=""> Tempo Traveller For Rent In Madurai</h3>
            <p>
              Yours tempo traveller near me; Abu tours and travels – Tempo
              traveller for rent in madurai
            </p>
          
   

            <p>
              <FaBusAlt className="temp_page_icons" /> Mini Bus Hire 12 Seater
              in Madurai
            </p>
            <br />
            <p>
              <FaPhoneVolume className="temp_page_icons" /> 93603 71716
            </p>
            <br />

            <p>
              <FaLocationArrow className="temp_page_icons" /> 151 ,Dopi colony,
              Alagar kovil main road, K.pudur, Madurai, Tamil Nadu 625007
            </p>
            <br />
            <p>
              <MdAccessTimeFilled className="temp_page_icons" />
              Tuesday: 6am–11pm Wednesday: 6am–11pm Thursday: 6am–11pm Friday:
              6am–11pm Saturday: 6am–11pm Sunday: 6am–11pm Monday: 6am–11pm
            </p>
          </Col>

          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
            <img src={Gallery} alt="" className="gallery" />
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xm={12}>
            <center>
              <h1>FAQ</h1>
            </center>
            <br />
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Is Tempo Traveller is safe for night journey?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>Yes, You can use in night journey.</p>
                  <br />
                  <p>
                    How much average time taken by Tempo Traveller from Madurai
                    to rameshwaram?
                  </p>
             

                
                  <br />
                  <p>Its takes 3 to 4 hours</p>
                </Accordion.Body>
              </Accordion.Item>

              <br />
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What does your price include? that?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                    The mileage and No. of days are calculated from our garage
                    to garage in city. In case you leave the Tempo Traveller at
                    any other place than from where you have rented then No. of
                    days and mileage will be calculated up to our office in city
                    where you rent it from.
                  </p>{" "}
                </Accordion.Body>
              </Accordion.Item>

              <br />
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What are your payment terms ?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                    You can pay us in advance through Bank remittance – HDFC and
                    Kotak Mahindra Bank. In certain cases we may ask you to pay
                    us in advance as a condition. In due course of time, we
                    shall also be accepting secured online credit card payments.
                  </p>{" "}
                  <br />
                  <p>Abu holds the right to cancel the booking in such case.</p>
                </Accordion.Body>
              </Accordion.Item>

              <br />
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                What happens if I have to cancel or change my reservation?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                  Normally, there is no problem and there is no penalty for that. But, in certain circumstances where we ask for advance payment as a condition, it may levy cancellation or amendment charges which we shall let you know at the time you make your booking.
                  </p>{" "}
                  <br />
                </Accordion.Body>
              </Accordion.Item>

              <br />

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                What will happen if I have to change my tour programme during the trip?
                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                  This is possible and in case of longer duration, we shall work out additional charges and let you know at the time when you make changes and in cases of lesser number of days we shall let you know the refund amount due to you which will be paid to you.

                  </p>
                  <br />
                </Accordion.Body>
              </Accordion.Item>

              <br />

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                Is the boarding and lodging of driver included in the price and will he be at our disposal all the time?

                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                  Yes, the boarding and lodging of driver is included in the price but they normally expect some tips after they have provided you satisfactory service which is of course not obligatory. Normally, our driver will be at disposal you can start your trip at any time in the morning and even use till late evening. For example if you occasionally use him from 6 AM to 10 PM, it is o.k. if it is not every day.
                  </p>{" "}
                  <br />
                </Accordion.Body>
              </Accordion.Item>
<br />
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                Why should I hire a Tempo Traveller from you ?

                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                  TempoTravelerRent.com, founded in year 2003, is comparatively new player in the market, but prides itself in providing quality services to its clients. We may not be the cheapest Tempo Traveler rental company but we definitely provide quality and reliable service at reasonable price.                  </p>{" "}
                  <br />
                </Accordion.Body>
              </Accordion.Item>
<br />
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                TempoTravelerRent.com, founded in year 2003, is comparatively new player in the market, but prides itself in providing quality services to its clients. We may not be the cheapest Tempo Traveler rental company but we definitely provide quality and reliable service at reasonable price.

                </Accordion.Header>
                <Accordion.Body>
                  <br />
                  <p>
                  Just visit <a href="http://abutoursandtravels.com   ">http://abutoursandtravels.com </a>                </p>{" "}
                  <br />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Car_rentals;
